import React, { useState, useEffect } from "react";
import "./App.css";

const calculateElapsedTime = (startDate) => {
  const now = new Date();
  const elapsed = now - startDate;

  const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));
  const hours = Math.floor((elapsed / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((elapsed / (1000 * 60)) % 60);
  const seconds = Math.floor((elapsed / 1000) % 60);

  return { days, hours, minutes, seconds };
};

function App() {
  const startDate = new Date("2024-10-18T00:00:00");
  const [timeElapsed, setTimeElapsed] = useState(calculateElapsedTime(startDate));
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeElapsed(calculateElapsedTime(startDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate]);

  return (
    <div className="App">
      <header className="App-header">
        <p
          style={{
            fontSize: "3em",
            marginBottom: "20px",
            fontFamily: "monospace",
            fontWeight: "bold",
          }}
        >
          Tempo desde o último Tomelo Day
        </p>
        <div className="countdown-container">
          <div>
            <div className="countdown-box">
              <span className="time">{timeElapsed.days}</span>
            </div>
            <span className="label">DIAS</span>
          </div>
          <div>
            <div className="countdown-box">
              <span className="time">{timeElapsed.hours}</span>
            </div>
            <span className="label">HORAS</span>
          </div>
          <div>
            <div className="countdown-box">
              <span className="time">{timeElapsed.minutes}</span>
            </div>
            <span className="label">MINUTOS</span>
          </div>
          <div>
            <div className="countdown-box">
              <span className="time">{timeElapsed.seconds}</span>
            </div>
            <span className="label">SEGUNDOS</span>
          </div>
        </div>
        <img
          src="/aviso.png"
          alt="Tomelo Day"
          style={{ width: "300px", marginTop: "20px" }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            textAlign: "center",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <img
            src="/cabeca.png"
            alt="Tomelo"
            style={{ width: "80px", cursor: "pointer" }}
          />
          {isHovered && (
            <div>
              <div
                style={{
                  position: "absolute",
                  bottom: "100%", 
                  right: "0",
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  whiteSpace: "nowrap",
                  zIndex: 1, 
                }}
              >
                Preparado para o Tomelo Day?
              </div>
              <p
                style={{
                  position: "absolute",
                  bottom: "120%", 
                  right: "0",
                  backgroundColor: "rgba(0, 0, 0, 0.75)",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  whiteSpace: "nowrap",
                  zIndex: 1, 
                  fontSize: "0.6em",
                }}
              >
                que beleza cabeça amigo
              </p>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
